import React from 'react'
import { useStoreConfig } from '@config/hooks/useStoreConfig'
import { useTranslation } from 'next-i18next'
import Script from 'next/script'

/**
 * Live Chat scripts
 */
export const LiveChatScripts = () => {
    const { t } = useTranslation()
    const {
        base: {
            live_chat: { enabled, license, source }
        }
    } = useStoreConfig()
    const SCRIPT_STRATEGY = 'afterInteractive'

    if (!enabled || !source || !license) return null

    return (
        <>
            <Script id="live-chat-script-pwa" strategy={SCRIPT_STRATEGY}>
                {`
              window.__lc = window.__lc || {};
    window.__lc.license = ${license};
    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="${source}",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
              `}
            </Script>
            <noscript>
                <a
                    href={`https://www.livechat.com/chat-with/${license}/`}
                    rel="nofollow"
                >
                    {t('Chat with us')}
                </a>
                {t(', powered by ')}
                <a
                    href="https://www.livechat.com/?welcome"
                    rel="noopener nofollow noreferrer"
                    target="_blank"
                >
                    {t('LiveChat')}
                </a>
            </noscript>
        </>
    )
}
