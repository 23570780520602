import { getThemeConfig } from './utils/getThemeConfig'

export type GlobalPropsContextType = {
    plpConfig: {
        plpColumns: number
        plpQuickAdd: boolean
        isHorizontalFilterMenu: boolean
        showLoadMoreButton: boolean
    }
    navigationConfig: {
        pageLoaderProgress: boolean
        bottomUtilityBar: boolean
    }
    cartConfig: {
        cartType: string
        showMiniCartAccordions: boolean
    }
    pdpConfig: {
        pdpBreadcrumbsType: string
        galleryDisplay: string
    }
    blogConfig: {
        showPublishedDate: boolean
        showAuthor: boolean
        showShareButton: boolean
        articlesLayout: string
    }
    appConfig: {
        isThemeEditorOpen: boolean
    }
    checkoutConfig: {
        isDiscountStackable: boolean
    }
}

const themeConfig = getThemeConfig()
export const useGlobalProps = (): GlobalPropsContextType => themeConfig
