import React from 'react'
import { getFirstNotEmpty } from '@corratech/pylot-utils'
import { useStoreConfig } from '@config/hooks/useStoreConfig'
import Head from 'next/head'
import { CommonMetaTags } from '@corratech/pylot-seo/src/CommonMetaTags'

export type BlogSeoProps = {
    titleOverride?: string
    robotValue?: string
    keywordValue?: string
    canonicalUrl?: string
    seoImage?: string
    descriptionOverride?: string
}

const BlogSeo = ({
    titleOverride,
    robotValue,
    keywordValue,
    seoImage,
    canonicalUrl,
    descriptionOverride
}: BlogSeoProps): JSX.Element | null => {
    const config = useStoreConfig()
    const { seo } = config

    const title = titleOverride

    if (!title) {
        return null
    }

    const description = getFirstNotEmpty([
        descriptionOverride,
        seo.default.description
    ])

    const imageFallBackContent = {
        src: seo.default.openGraph?.images?.[0]?.url,
        alt: description,
        height: 630,
        width: 1200
    }

    const imageContentFunction = () => {
        if (typeof window !== 'undefined' && seoImage) {
            const img = new Image()
            img.src = seoImage
            img.alt = description
            return img
        }
        return imageFallBackContent
    }

    const imageContent = imageContentFunction()

    const generalData = {
        title: title,
        keyword: String(keywordValue),
        description: String(description),
        canonicalUrl
    }

    const twitterData = {
        title: title,
        description: description,
        domain: seo.default.twitter?.handle,
        url: canonicalUrl,
        card: seo.default.twitter?.cardType,
        image: imageContent?.src,
        site: seo.default.twitter?.handle,
        creator: seo.default.twitter?.creator
    }

    const ogData = {
        title: title,
        description: description,
        url: canonicalUrl,
        siteName: seo.default.openGraph?.site_name,
        image: imageContent?.src,
        imageAlt: imageContent?.alt,
        imageWidth: imageContent?.width || imageFallBackContent.width,
        imageHeight: imageContent?.height || imageFallBackContent.height
    }

    return (
        <>
            <CommonMetaTags
                generalData={generalData}
                twitterData={twitterData}
                ogData={ogData}
            />
            {robotValue && (
                <Head>
                    <meta name="robots" content={robotValue} />
                </Head>
            )}
        </>
    )
}

export default BlogSeo
