export enum QueryType {
    query = 'query',
    mutation = 'mutation',
    subscription = 'subscription'
}

export type GetQueryParamsResult = {
    operationType: QueryType
    operationNickname: string
    firstQueryName: string
}

export const operationToMethod = {
    [QueryType.query]: 'GET',
    [QueryType.mutation]: 'POST',
    [QueryType.subscription]: 'POST'
}

/**
 * Extracts query parameters from shortened graphql query
 * @param query short query string (processed with `getShortQueryString`)
 * @returns query parameters necessary for custom headers and request body
 */
export const getQueryParams = (query: string): GetQueryParamsResult => {
    const operationMethods = Object.keys(operationToMethod).join('|')
    const queryParamExtractionRegExp = new RegExp(
        `^(?:(${operationMethods}) ?)?(?:(\\w+)(?:\\(.+?\\))?)?\\{(?:\\w+:)?(\\w+)`
    )

    const params = queryParamExtractionRegExp.exec(query) || []

    if (!params.length) {
        const errorMessage = `Unable to get query parameters, i.e. 'type', 'operationNickname', 'firstQueryName', from the following query: "${query}"`

        console.error(errorMessage)
        throw new Error(errorMessage)
    }

    const firstQueryName = params[3]
    const [, operationType = QueryType.query, operationNickname] = params

    return {
        operationType: operationType as QueryType,
        operationNickname,
        firstQueryName
    }
}

// This will remove all space from queries except absolute minimum spaces
export const getShortQueryString = (query: string): string => {
    return query
        .replace(/#.*\n/g, '')
        .replace(/[\s|,]*\n+[\s|,]*/g, ' ')
        .replace(/:\s/g, ':')
        .replace(/,\s/g, ',')
        .replace(/\)\s\{/g, '){')
        .replace(/\}\s/g, '}')
        .replace(/\{\s/g, '{')
        .replace(/\s\}/g, '}')
        .replace(/\s\{/g, '{')
        .replace(/\)\s/g, ')')
        .replace(/\(\s/g, '(')
        .replace(/\s\)/g, ')')
        .replace(/\s\(/g, '(')
        .replace(/=\s/g, '=')
        .replace(/\s=/g, '=')
        .replace(/@\s/g, '@')
        .replace(/\s@/g, '@')
        .replace(/\s\$/g, '$')
        .replace(/\s\./g, '.')
        .trim()
}
