import React, { ReactNode, FC } from 'react'
import { useTranslation } from 'next-i18next'
import ErrorBoundaryInner from '@components/ErrorBoundary/ErrorBoundaryInner'

interface WrapperProps {
    children?: ReactNode
    global?: boolean
}

const ErrorBoundary: FC<WrapperProps> = ({ children, global }) => {
    const { t } = useTranslation(['common'])

    return (
        <ErrorBoundaryInner t={t} global={global}>
            {children}
        </ErrorBoundaryInner>
    )
}

export default ErrorBoundary
