import type { RequestInit } from '@vercel/fetch'
import { useRouter } from 'next/router'
import type { SWRConfiguration, SWRResponse } from 'swr'
import useSWR from 'swr'
import type { GraphQLError } from '../graphqlFetch'
import { graphqlFetch } from '../graphqlFetch'
import { getIsSignedIn } from '@corratech/pylot-cart-manager/src/utils/getIsSignedIn'

export type SwrUseQueryOutput<OutputInterface> = SWRResponse<
    { data: OutputInterface; errors?: GraphQLError[] },
    // `any` is the default type for useSWR errors - see node_modules/swr/dist/use-swr.d.ts
    any
>

export type UseQueryOptions = {
    overrideFetcher: typeof graphqlFetch
}

// type declaration uses generics so that user can specific input, output, and error types
// on a per-query basis (ideally in wrapper hooks)

const useQueryBase = <VariablesInterface, OutputInterface>(
    query: string | undefined | null,
    variables?: VariablesInterface,
    swrOptions?: SWRConfiguration,
    fetchOptions?: RequestInit,
    useQueryOptions?: UseQueryOptions
): SwrUseQueryOutput<OutputInterface> => {
    const { locale } = useRouter()
    /*
        IMPORTANT:
        In order for useSWR caching to work, the query key should include
        ONLY the VALUES of variables, because the variable-object is not stable.
        ("not stable" meaning it is re-created on each render and useSWR would
        think each render has a different query)

        To work around this, in each run of this hook, we sort all of the variables
        to ensure a consistent order, and then we separate `variables` object into keys and values.
        The variable values are spread into `queryKey`.

        When we run the fetch, we re-combine the keys and values, which is required for
        our graphql fetcher to function correctly.
     */

    const sortedVariables = variables
        ? Object.entries(variables).sort(([key1], [key2]) => {
              if (key1 < key2) return -1
              else if (key1 > key2) return 1
              else return 0
          })
        : null

    const queryKey = sortedVariables
        ? [
              query,
              locale,
              ...sortedVariables!.map((variableEntry) =>
                  JSON.stringify(variableEntry[1])
              )
          ]
        : [query, locale]

    // in some cases we don't want to use the SWR key as the fetch args
    // example: in cart, we need to use the same SWR key across `cart` and `customerCart` queries
    const fetcher = useQueryOptions?.overrideFetcher || graphqlFetch

    return useSWR(
        query ? queryKey : null,
        (_query, locale, ...variableValues) => {
            return fetcher<VariablesInterface, OutputInterface>({
                query: _query!,
                // @ts-ignore // @TODO: fix this ts types
                variables: variableValues
                    ? variableValues.reduce((obj, val, i) => {
                          // @ts-ignore // @TODO: fix this ts types
                          obj[sortedVariables![i][0]!] = JSON.parse(val!)
                          return obj
                      }, {})
                    : {},
                fetchOptions,
                locale: locale as string | undefined,
                isSignedIn: getIsSignedIn()
            })
        },
        swrOptions
    ) as SwrUseQueryOutput<OutputInterface>
}

const useQueryWithErrorHandling = <VariablesInterface, OutputInterface>(
    query: string | undefined | null,
    variables?: VariablesInterface,
    swrOptions?: SWRConfiguration,
    fetchOptions?: RequestInit,
    useQueryOptions?: UseQueryOptions
): SwrUseQueryOutput<OutputInterface> => {
    return useQueryBase<VariablesInterface, OutputInterface>(
        query,
        variables,
        swrOptions,
        fetchOptions,
        useQueryOptions
    )
}

export { useQueryWithErrorHandling as useQuery, useQueryBase }
