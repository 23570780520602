/**
 * Mapping from URL root path (NextJS locale) to the real locale (file) and store code
 */
module.exports = {
    en: {
        storeCode: process.env.NEXT_PUBLIC_STORE_CODE,
        locale: 'en',
        countryCode: 'US',
        storeLabel: 'USGB (USD)',
        defaultCurrencyCode: 'USD'
    }
}
