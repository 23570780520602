/** The list of available currency codes */
export enum CurrencyEnum {
    Afn = 'AFN',
    All = 'ALL',
    Azn = 'AZN',
    Dzd = 'DZD',
    Aoa = 'AOA',
    Ars = 'ARS',
    Amd = 'AMD',
    Awg = 'AWG',
    Aud = 'AUD',
    Bsd = 'BSD',
    Bhd = 'BHD',
    Bdt = 'BDT',
    Bbd = 'BBD',
    Byn = 'BYN',
    Bzd = 'BZD',
    Bmd = 'BMD',
    Btn = 'BTN',
    Bob = 'BOB',
    Bam = 'BAM',
    Bwp = 'BWP',
    Brl = 'BRL',
    Gbp = 'GBP',
    Bnd = 'BND',
    Bgn = 'BGN',
    Buk = 'BUK',
    Bif = 'BIF',
    Khr = 'KHR',
    Cad = 'CAD',
    Cve = 'CVE',
    Czk = 'CZK',
    Kyd = 'KYD',
    Gqe = 'GQE',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Kmf = 'KMF',
    Cdf = 'CDF',
    Crc = 'CRC',
    Hrk = 'HRK',
    Cup = 'CUP',
    Dkk = 'DKK',
    Djf = 'DJF',
    Dop = 'DOP',
    Xcd = 'XCD',
    Egp = 'EGP',
    Svc = 'SVC',
    Ern = 'ERN',
    Eek = 'EEK',
    Etb = 'ETB',
    Eur = 'EUR',
    Fkp = 'FKP',
    Fjd = 'FJD',
    Gmd = 'GMD',
    Gek = 'GEK',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gtq = 'GTQ',
    Gnf = 'GNF',
    Gyd = 'GYD',
    Htg = 'HTG',
    Hnl = 'HNL',
    Hkd = 'HKD',
    Huf = 'HUF',
    Isk = 'ISK',
    Inr = 'INR',
    Idr = 'IDR',
    Irr = 'IRR',
    Iqd = 'IQD',
    Ils = 'ILS',
    Jmd = 'JMD',
    Jpy = 'JPY',
    Jod = 'JOD',
    Kzt = 'KZT',
    Kes = 'KES',
    Kwd = 'KWD',
    Kgs = 'KGS',
    Lak = 'LAK',
    Lvl = 'LVL',
    Lbp = 'LBP',
    Lsl = 'LSL',
    Lrd = 'LRD',
    Lyd = 'LYD',
    Ltl = 'LTL',
    Mop = 'MOP',
    Mkd = 'MKD',
    Mga = 'MGA',
    Mwk = 'MWK',
    Myr = 'MYR',
    Mvr = 'MVR',
    Lsm = 'LSM',
    Mro = 'MRO',
    Mur = 'MUR',
    Mxn = 'MXN',
    Mdl = 'MDL',
    Mnt = 'MNT',
    Mad = 'MAD',
    Mzn = 'MZN',
    Mmk = 'MMK',
    Nad = 'NAD',
    Npr = 'NPR',
    Ang = 'ANG',
    Ytl = 'YTL',
    Nzd = 'NZD',
    Nic = 'NIC',
    Ngn = 'NGN',
    Kpw = 'KPW',
    Nok = 'NOK',
    Omr = 'OMR',
    Pkr = 'PKR',
    Pab = 'PAB',
    Pgk = 'PGK',
    Pyg = 'PYG',
    Pen = 'PEN',
    Php = 'PHP',
    Pln = 'PLN',
    Qar = 'QAR',
    Rhd = 'RHD',
    Ron = 'RON',
    Rub = 'RUB',
    Rwf = 'RWF',
    Shp = 'SHP',
    Std = 'STD',
    Sar = 'SAR',
    Rsd = 'RSD',
    Scr = 'SCR',
    Sll = 'SLL',
    Sgd = 'SGD',
    Skk = 'SKK',
    Sbd = 'SBD',
    Sos = 'SOS',
    Zar = 'ZAR',
    Krw = 'KRW',
    Lkr = 'LKR',
    Sdg = 'SDG',
    Srd = 'SRD',
    Szl = 'SZL',
    Sek = 'SEK',
    Chf = 'CHF',
    Syp = 'SYP',
    Twd = 'TWD',
    Tjs = 'TJS',
    Tzs = 'TZS',
    Thb = 'THB',
    Top = 'TOP',
    Ttd = 'TTD',
    Tnd = 'TND',
    Tmm = 'TMM',
    Usd = 'USD',
    Ugx = 'UGX',
    Uah = 'UAH',
    Aed = 'AED',
    Uyu = 'UYU',
    Uzs = 'UZS',
    Vuv = 'VUV',
    Veb = 'VEB',
    Vef = 'VEF',
    Vnd = 'VND',
    Che = 'CHE',
    Chw = 'CHW',
    Xof = 'XOF',
    Wst = 'WST',
    Yer = 'YER',
    Zmk = 'ZMK',
    Zwd = 'ZWD',
    Try = 'TRY',
    Azm = 'AZM',
    Rol = 'ROL',
    Trl = 'TRL',
    Xpf = 'XPF'
}
