import type { RequestInit } from '@vercel/fetch'
import { logFetchQueryAsCurl, logGqlErrors } from './logGraphqlFetchErrors'
import {
    getQueryParams,
    getShortQueryString,
    QueryType
} from '../../api-utils/graphqlUtils'
import { getStoreCode } from '../../config/hooks/useStoreConfig'
import RouterLocaleInfo from '../../next-i18next.router-locale-info'
export interface GraphQLError {
    message: string
    extensions: {
        category: string
    }
    locations: Array<{
        line: number
        column: number
    }>
    debugMessage: string
    path?: string[]
}

const operationToMethod = {
    [QueryType.query]: 'GET',
    [QueryType.mutation]: 'POST',
    [QueryType.subscription]: 'POST'
}

export type GraphqlFetchOutput<OutputInterface> = {
    data: OutputInterface
    errors?: GraphQLError[]
}

export type GraphqlFetchOutputPromise<OutputInterface> = Promise<
    GraphqlFetchOutput<OutputInterface>
>

export type GraphqlFetchParameters<VariablesInterface> = {
    query: string
    variables?: VariablesInterface
    locale?: string
    fetchOptions?: RequestInit
    isSignedIn?: boolean
}

export const graphqlFetch = async <VariablesInterface, OutputInterface>({
    query,
    variables,
    fetchOptions,
    // isSignedIn, // uncomment if needed!
    locale
}: GraphqlFetchParameters<VariablesInterface>): GraphqlFetchOutputPromise<OutputInterface> => {
    const shortQuery = getShortQueryString(query)
    const { operationNickname, firstQueryName } = getQueryParams(shortQuery)
    let { operationType } = getQueryParams(shortQuery)
    //We should be able to switch queries (GET) to POST, but mutations (POST) cannot be switched to GET
    if (fetchOptions?.method === 'POST') operationType = QueryType.mutation

    const operationNicknameFormatted = operationNickname
        ? `&operationName=${operationNickname}`
        : ''

    // get the URL ending for queries with parameters
    // leave it empty for mutations
    const urlEnding =
        operationType === QueryType.query
            ? `?query=${encodeURIComponent(
                  shortQuery
              )}${operationNicknameFormatted}&variables=${encodeURIComponent(
                  JSON.stringify(variables || {})
              )}`
            : ''

    const headers = {
        ...fetchOptions?.headers
        // custom pylot header
    } as Record<string, any>

    if (firstQueryName == 'products') {
        headers['x-pylot-query'] = firstQueryName
        headers['cache-control'] = 'no-cache'
    } else {
        headers['x-pylot-query'] = firstQueryName
    }

    if (operationToMethod[operationType] === 'POST') {
        headers['Content-Type'] = 'application/json'
    }

    if (process.env.NEXT_PUBLIC_X_PYLOT_BACKEND) {
        headers['x-pylot-backend'] = process.env.NEXT_PUBLIC_X_PYLOT_BACKEND
    }

    headers.Store = getStoreCode(locale)
    if (locale) {
        const localeInfo = RouterLocaleInfo[locale as string]
        headers['x-currency'] = localeInfo?.defaultCurrencyCode
        headers['x-country'] = localeInfo?.countryCode
    }
    if (typeof window !== 'undefined') {
        const savedToken = window.localStorage.getItem('pylot_token') || null
        if (savedToken) {
            headers.Authorization = `Bearer ${savedToken}`
        }
    }

    // init the fetch config early so we can manipulate below
    const fetchConfig = {
        ...fetchOptions,
        method: operationToMethod[operationType],
        headers
    }

    // Add body ONLY IF not a query
    // Adding body to a query breaks the query
    if (operationType !== QueryType.query)
        fetchConfig.body = JSON.stringify({
            query,
            variables
        })

    //
    // You can add a conditional here w/ firstQueryName to test local services.
    // For example, if you're locally testing a service which replaces the `products` and `categories` endpoints:
    // //
    // const urlBeginning = ['products', 'categories'].includes(firstQueryName)
    //     ? 'http://localhost:4000/graphql'
    //     : process.env.NEXT_PUBLIC_API_GATEWAY_URL

    const urlBeginning = process.env.NEXT_PUBLIC_API_GATEWAY_URL

    const fetchUrl = urlBeginning + urlEnding
    let res
    try {
        res = await fetch(
            fetchUrl,
            // @ts-ignore for some reason, typescript insists that we need `body`,
            // which is only appropriate for POST requests
            fetchConfig
        )
    } catch (e: any) {
        // Log service connection errors
        logFetchQueryAsCurl(fetchUrl, fetchConfig)
        if (e instanceof Error)
            throw new Error(`Failed to connect to the service: ${e.message}`)
        else throw e
    }

    // We don't return 'await res.json()' right away in order to be able to log erroneous response text and headers
    const resText = await res.text()
    try {
        const resJson = JSON.parse(resText)
        if (resJson && (resJson['errors'] || resJson['error'])) {
            logGqlErrors(fetchUrl, fetchConfig, res, resJson)
            if (
                // check if any of the errors are 'graphql-authorization' errors
                resJson.errors.filter(
                    (error: { extensions?: { category: string } }) =>
                        error.extensions?.category === 'graphql-authorization'
                ).length >= 1
            ) {
                // This is needed to remove the httpOnly authorization token cookie
                await graphqlFetch({
                    query: /* GraphQL */ `
                        mutation Logout {
                            revokeCustomerToken {
                                result
                            }
                        }
                    `
                })
                window.location.hash = 'session-expired'
                window.localStorage.removeItem('pylot_token')
                window.localStorage.removeItem('cart_id')
                window.localStorage.removeItem('sign_in_as_customer')
                window.localStorage.setItem('session_invalid', '1')
                window.dispatchEvent(new CustomEvent('clearSwrCache'))
            }
        }
        return resJson
    } catch (e) {
        // Show the actual response text and error message
        console.error(e)
        logFetchQueryAsCurl(fetchUrl, fetchConfig)
        throw new Error(`Failed to process response as JSON data: ${resText}`)
    }
}
