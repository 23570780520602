/* eslint-disable @typescript-eslint/no-var-requires */
require('dotenv').config()
const path = require('path')
const originalPath = path.resolve('./public/locales')
const routerLocaleInfo = require('./next-i18next.router-locale-info')

const DEFAULT_LOCALE = 'en'

module.exports = {
    i18n: {
        defaultLocale: DEFAULT_LOCALE,
        locales: Object.keys(routerLocaleInfo)
    },
    nsSeparator: '||',
    keySeparator: '|',
    serializeConfig: false,
    returnNull: false,
    localePath: (locale, namespace) => {
        const realLocale =
            routerLocaleInfo[locale]?.locale ??
            locale ??
            routerLocaleInfo[DEFAULT_LOCALE].locale
        return `${originalPath}/${realLocale}/${namespace}.json`
    },
    ns: [
        'account',
        'auth',
        'blog',
        'cart',
        'checkout',
        'common',
        'contactUs',
        'orders',
        'pdp',
        'plp',
        'wishlist'
    ]
}
