import React, { ErrorInfo, ReactNode } from 'react'
import dynamic from 'next/dynamic'
import { TFunction } from 'next-i18next'

const Logo = dynamic(() => import('@components/icons/Logo'))

interface Props {
    children?: ReactNode
    t: TFunction
    global?: boolean
}

interface State {
    hasError: boolean
}

class ErrorBoundaryInner extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(): State {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        window.console.error('Client Error: ', error)
        window.console.error('Client Error Info: ', errorInfo)
    }

    render(): ReactNode {
        // Check if the error is thrown
        if (this.state.hasError) {
            return (
                <div className="flex flex-col align-center justify-center text-center fit">
                    <div className="flex justify-center mb-10">
                        {this.props.global ? (
                            <Logo width={180} height={36} />
                        ) : null}
                    </div>
                    <h1 className="text-6xl mb-3">
                        {this.props.t('Oops! We ran into an issue.')}
                    </h1>
                    <h3 className="text-3xl">
                        <a className="underline cursor-pointer" href="/">
                            {this.props.t('Return to our homepage')}
                        </a>
                        &nbsp;
                        {this.props.t('to continue shopping.')}
                    </h3>
                </div>
            )
        }

        // Return children components in case of no error
        return this.props.children
    }
}

export default ErrorBoundaryInner
