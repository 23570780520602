import type { PreloadConfig } from '@preload/graphql/getConfig.d'

export type FrontendPreloadConfig = PreloadConfig & {
    [K in keyof Omit<PreloadConfig, '__typename'>]?: PreloadConfig[K] & {
        queryPath?: string
        fetchConfig?: Record<string, unknown>
    }
}

export const preload: FrontendPreloadConfig = {
    mobileMenu: {
        queryPath: 'framework/pylot/hooks/cms/graphql/getCMSBlocks',
        variables: { identifiers: ['megamenu_mobile_pwa'] }
    },
    desktopMenu: {
        queryPath: 'framework/pylot/hooks/cms/graphql/getCMSBlocks',
        variables: { identifiers: ['megamenu_pwa'] }
    },
    footer: {
        queryPath: 'framework/pylot/hooks/cms/graphql/getCMSBlocks',
        variables: {
            identifiers: [
                'footer-copyright-block',
                'footer_links',
                'footer-social-media'
            ]
        }
    },
    contactUs: {
        queryPath: 'framework/pylot/hooks/cms/graphql/getCMSBlocks',
        variables: {
            identifiers: ['header_contact_us_block_pwa']
        }
    }
}
export default preload
