import { FC, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import cn from 'classnames'
import { useCart } from '@corratech/pylot-cart-manager'
import { Bag, Search, User } from '@components/icons'
import { useUI } from '@corratech/pylot-ui/context'
import { useAuthUI } from '@corratech/pylot-auth-manager'
import { Button } from '@corratech/pylot-ui'
import s from './UserNav.module.scss'
import { useAccount } from '@corratech/pylot-auth-manager'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { SimpleCartItem } from '@pylot-data/pylotschema'
import { useGlobalProps } from '@pylot-data/hooks/props/useGlobalProps'
import { setUser } from '@lib/utils/datadog'

const RichContent = dynamic(() =>
    import('@corratech/pylot-rich-content').then((mod) => mod.RichContent)
)
const DropdownMenu = dynamic(() => import('./DropdownMenu'))

interface Props {
    handleSearchToggle: (flag: boolean) => void
    contactUsContent?: string
    className?: string
}

const UserNav: FC<Props> = ({
    className,
    contactUsContent,
    handleSearchToggle
}) => {
    const { t } = useTranslation()
    //@ts-ignore
    const { data: cartData } = useCart(undefined, undefined, true)
    const { data } = useAccount()
    const [isTokenExist, setIsTokenExist] = useState(false)
    const { toggleSidebar, displaySearch } = useUI()
    const { openAuthModal } = useAuthUI()
    const { cartConfig } = useGlobalProps()
    const customer = data?.data?.customer
    const router = useRouter()
    const cartItemsCount = cartData?.data?.cart?.items?.reduce(
        (itemCount, item) => {
            if (item) {
                return itemCount + (item as SimpleCartItem)?.quantity
            }
            //return 0 to make the itemCount unchanged and prevent NAN return type
            return 0
        },
        0
    )

    const redirectCart = () => router.push('/cart')

    const handleCartBagRedirection = () => {
        if (cartConfig.cartType == 'cart_page') {
            redirectCart()
        } else {
            toggleSidebar()
        }
    }

    const isClientSide = typeof window !== 'undefined'

    useEffect(() => {
        if (isClientSide) {
            const token = window.localStorage.getItem('pylot_token')
            setIsTokenExist(!!token)
        }
    }, [isClientSide, customer])

    useEffect(() => setUser(customer?.email), [customer])

    return (
        <nav className={cn(s.root, className)}>
            <ul className={s.list}>
                <li className={cn(s['contact-menu'], s.item, 'contact-menu')}>
                    {contactUsContent && (
                        <div className="cms-content">
                            <RichContent html={contactUsContent} />
                        </div>
                    )}
                </li>
                <li className={cn(s['search-menu'], s.item, 'search-menu')}>
                    <Button
                        variant="link"
                        type="button"
                        className={cn(displaySearch ? 'active' : '')}
                        onClick={() => handleSearchToggle(true)}
                        aria-label={t('Search Menu')}
                    >
                        <span aria-hidden="true">
                            <Search />
                        </span>
                        <span className={cn(s['search-label'], 'search-label')}>
                            {t('Search')}
                        </span>
                    </Button>
                </li>
                <li className={cn(s['user-menu'], s.item, 'user-menu')}>
                    {isTokenExist ? (
                        <DropdownMenu />
                    ) : (
                        <Button
                            className={s.avatarButton}
                            variant="link"
                            type="button"
                            onClick={() => openAuthModal()}
                            aria-label={t('Account Menu')}
                        >
                            <span aria-hidden="true">
                                <User />
                            </span>
                            <span
                                className={cn(
                                    s['account-label'],
                                    'account-label'
                                )}
                            >
                                {t('Account')}
                            </span>
                        </Button>
                    )}
                </li>
                <li className={cn(s['cart-menu'], s.item, 'cart-menu')}>
                    <Button
                        className={cn(s['bag-btn'])}
                        variant="link"
                        onClick={handleCartBagRedirection}
                        aria-label={t('Cart')}
                    >
                        <span aria-hidden="true">
                            <Bag />
                        </span>
                        <span className={cn(s['cart-label'], 'cart-label')}>
                            {t('Cart')}
                        </span>
                        <span
                            className={cn(
                                s['cart-count'],
                                'cart-count',
                                cartItemsCount && 'has-count'
                            )}
                        >
                            {cartItemsCount ?? 0}
                        </span>
                    </Button>
                </li>
            </ul>
        </nav>
    )
}

export default UserNav
