const Search = ({ ...props }) => {
    return (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_2259_27945)">
                <path
                    d="M12.9375 7.59097C12.9375 6.09913 12.3449 4.66839 11.29 3.6135C10.2351 2.55861 8.80434 1.96597 7.3125 1.96597C5.82066 1.96597 4.38992 2.55861 3.33502 3.6135C2.28013 4.66839 1.6875 6.09913 1.6875 7.59097C1.6875 9.08282 2.28013 10.5136 3.33502 11.5684C4.38992 12.6233 5.82066 13.216 7.3125 13.216C8.80434 13.216 10.2351 12.6233 11.29 11.5684C12.3449 10.5136 12.9375 9.08282 12.9375 7.59097ZM11.8512 13.325C10.6066 14.3128 9.02812 14.9035 7.3125 14.9035C3.27305 14.9035 0 11.6304 0 7.59097C0 3.55152 3.27305 0.278473 7.3125 0.278473C11.352 0.278473 14.625 3.55152 14.625 7.59097C14.625 9.3066 14.0344 10.8851 13.0465 12.1296L17.7539 16.8371C18.0844 17.1675 18.0844 17.7019 17.7539 18.0289C17.4234 18.3558 16.8891 18.3593 16.5621 18.0289L11.8512 13.325Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_2259_27945">
                    <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(0 0.278473)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Search
