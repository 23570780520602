/** Core cart query name */
export const CART_QUERY_NAME = 'pylot_core_cart_query'

/**
 * Important things to consider:
 * 1. Make sure to include isSignedIn: Boolean! = false to the query
 *    and pass isSignedIn as true when necessary
 * 2. Position `applied_store_credit` before items `field`.
 *    This way store credit gets calculated only once and server returns the right value.
 */
export const cartFields = /* GraphQL */ `
 id
 email
 quote_id
 is_virtual
 is_employee
 checkoutUrl
 coupon_message
 applied_store_credit @include(if: $isSignedIn) {
    enabled
    current_balance {
        __typename
        currency
        value
    }
    applied_balance {
        __typename
        currency
        value
    }
 }
 dynamic_coupon {
    is_enabled
    is_employee_coupon_disabled
    max_fixed
    max_percent
  }
 items {
     id
     uid
     __typename
     product {
         id
         name
         sku
         availability_date
         product_brand
         product_brand_text
         stock_status
         thumbnail {
             label
             url
         }
         price_range {
             __typename
             minimum_price {
                 regular_price {
                     value
                     currency
                 }
                 final_price {
                     value
                     currency
                 }
                 discount {
                     amount_off
                 }
             }
         }
         special_price
         url_key
         __typename
         ... on ConfigurableProduct {
             variants {
                 attributes {
                     code
                     label
                     uid
                     value_index
                 }
                 product {
                     stock_status
                     sku
                     price_range {
                        __typename
                        minimum_price {
                            regular_price {
                                value
                                currency
                            }
                            final_price {
                                value
                                currency
                            }
                            discount {
                                amount_off
                            }
                        }
                    }
                     thumbnail {
                         url
                     }

                 }
             }
         }
     }
     ... on ConfigurableCartItem {
         configurable_options {
             configurable_product_option_uid
             configurable_product_option_value_uid
             option_label
             value_label
         }
     }
     ... on GiftCardCartItem {
         __typename
         amount {
             value
             currency
         }
         message
         recipient_email
         recipient_name
         sender_email
         sender_name
     }
     ... on BundleCartItem {
          prices {
            discounts {
              amount {
                currency
                value
              }
              label
            }
            price {
              currency
              value
            }
            row_total {
              currency
              value
            }
            row_total_including_tax {
              currency
              value
            }
            total_item_discount {
              currency
              value
            }
          }
          bundle_options {
            uid
            label
            type
            values {
              id
              label
              price
              quantity
            }
          }
     }
     quantity
     prices {
         __typename
         price {
             value
             currency
         }
         row_total_including_tax {
             value
             currency
         }
         row_total {
             value
             currency
         }
         total_item_discount {
             value
             currency
         }
         discounts {
            amount {
                currency
                value
            }
            label
        }
     }
 }

 prices {
     __typename
     grand_total {
         value
         currency
     }
     applied_taxes {
         label
         amount {
             value
             currency
         }
     }
     subtotal_including_tax {
         value
         currency
     }
     subtotal_excluding_tax {
         value
         currency
     }
     subtotal_with_discount_excluding_tax {
         value
         currency
     }
     discounts {
         amount {
             value
             currency
         }
         label
     }
 }
 shipping_addresses {
     firstname
     lastname
     company
     street
     city
     region {
         code
         label
         region_id
     }
     postcode
     telephone
     country {
         code
         label
     }
     available_shipping_methods @include(if: $isCheckout) {
         amount {
             currency
             value
         }
         available
         carrier_code
         carrier_title
         error_message
         method_code
         method_title
         price_excl_tax {
             value
             currency
         }
         price_incl_tax {
             value
             currency
         }
     }
     selected_shipping_method {
         carrier_code
         method_code
         carrier_title
         method_title
         shipping_location
         amount {
             value
             currency
         }
     }
 }
 billing_address @include(if: $isCheckout) {
     firstname
     lastname
     company
     street
     city
     region {
         code
         label
         region_id
     }
     postcode
     telephone
     country {
         code
         label
     }
 }
 selected_payment_method @include(if: $isCheckout) {
     code
     title
 }
 available_payment_methods @include(if: $isCheckout) {
     code
     title
 }
 applied_coupons {
     code
 }
 applied_gift_cards {
     code
     current_balance {
         currency
         value
     }
     applied_balance {
         currency
         value
     }
 }
 additionalInfo {
    golden_comment
	ira_checkbox
	ira_account
	ira_destination
    riskified_token
 }
 `
