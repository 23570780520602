import { GlobalPropsContextType } from '../useGlobalProps'
import themeConfigFile from './../../../../../themeEditorConfig.json'
import deepMerge from 'deepmerge'

type UnmergedThemeConfigFile = Record<string, GlobalPropsContextType>

export const getThemeConfig = (): GlobalPropsContextType => {
    const backendPlatform = process.env.NEXT_PUBLIC_X_PYLOT_BACKEND as string

    const ThemeConfigFile = themeConfigFile as unknown
    const mergedThemeConfig = ThemeConfigFile as GlobalPropsContextType
    const unmergedThemeConfig = ThemeConfigFile as UnmergedThemeConfigFile

    const themeConfigIsMerged = !unmergedThemeConfig.default

    if (themeConfigIsMerged) {
        return mergedThemeConfig
    }

    if (unmergedThemeConfig[backendPlatform]) {
        return deepMerge(
            unmergedThemeConfig.default,
            unmergedThemeConfig[backendPlatform]
        )
    }

    return unmergedThemeConfig.default
}
