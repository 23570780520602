import { datadogRum } from '@datadog/browser-rum'
import { Maybe } from '@pylot-data/pylotschema'
import { version } from 'package.json'

type datadogConfig = {
    enabled: boolean
    applicationId: string
    clientToken: string
    site: string
    service: string
    env: string
}

export const initDatadog = (config: datadogConfig) => {
    const { enabled, applicationId, clientToken, site, service, env } = config
    if (enabled) {
        datadogRum.init({
            version,
            applicationId,
            clientToken,
            site,
            service,
            env,
            sessionSampleRate: 50,
            sessionReplaySampleRate: 10,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            //For linking back traces between frontend/backend
            allowedTracingUrls: [
                /\/api\/graphql/,
                (uri) => /usgb\.io/.test(uri) && !/ubembed\.com/.test(uri),
                (uri) =>
                    /usgoldbureau\.com/.test(uri) &&
                    !/opg\.usgoldbureau\.com/.test(uri) &&
                    !/ubembed\.com/.test(uri) //Unbounce passes usgoldbureau.com in the query params
            ],
            beforeSend: (event) => {
                event.view.url_path = event.view.url.replace(
                    window.location.origin,
                    ''
                )
                event.view.name = event.view.url_path as string
                return true
            }
        })
    }
}

export const setUser = (email?: Maybe<string>) => {
    email &&
        datadogRum.setUser({
            email
        })
}
