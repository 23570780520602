const Bag = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0.65625 0C0.292578 0 0 0.292578 0 0.65625C0 1.01992 0.292578 1.3125 0.65625 1.3125H1.90039C2.0043 1.3125 2.09453 1.38633 2.11641 1.49023L3.52734 8.90039C3.70508 9.83008 4.51719 10.5 5.46055 10.5H13.3438C13.7074 10.5 14 10.2074 14 9.84375C14 9.48008 13.7074 9.1875 13.3438 9.1875H5.46055C5.14609 9.1875 4.87539 8.96328 4.81523 8.6543L4.66758 7.875H12.5563C13.4477 7.875 14.227 7.27891 14.4566 6.41758L15.5777 2.25312C15.7664 1.55859 15.2414 0.875 14.5223 0.875H3.28398C3.03516 0.35 2.50469 0 1.90039 0H0.65625ZM3.58477 2.1875H14.2379L13.1906 6.07578C13.1141 6.36289 12.8543 6.5625 12.5563 6.5625H4.41875L3.58477 2.1875ZM4.8125 14C5.1606 14 5.49444 13.8617 5.74058 13.6156C5.98672 13.3694 6.125 13.0356 6.125 12.6875C6.125 12.3394 5.98672 12.0056 5.74058 11.7594C5.49444 11.5133 5.1606 11.375 4.8125 11.375C4.4644 11.375 4.13056 11.5133 3.88442 11.7594C3.63828 12.0056 3.5 12.3394 3.5 12.6875C3.5 13.0356 3.63828 13.3694 3.88442 13.6156C4.13056 13.8617 4.4644 14 4.8125 14ZM14 12.6875C14 12.3394 13.8617 12.0056 13.6156 11.7594C13.3694 11.5133 13.0356 11.375 12.6875 11.375C12.3394 11.375 12.0056 11.5133 11.7594 11.7594C11.5133 12.0056 11.375 12.3394 11.375 12.6875C11.375 13.0356 11.5133 13.3694 11.7594 13.6156C12.0056 13.8617 12.3394 14 12.6875 14C13.0356 14 13.3694 13.8617 13.6156 13.6156C13.8617 13.3694 14 13.0356 14 12.6875Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default Bag
