import { ToastType, useUI } from '@corratech/pylot-ui/context'
import React, { useEffect } from 'react'
import { AlertMessage } from '@components/common/AlertMessage'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'

const TIMEOUT_AFTER_OPENING_TOAST = 300
const SUCCESS_TOAST_STAY_TIME = 5000

interface RenderAlertMessageProps {
    errorHeading: string | null
    successHeading: string | null
    errorSubHeading: string | null
    successSubHeading: string | null
    href: string | null
    linkText: string | null
}

export const useAlertMessage = () => {
    const { openToast, closeToast } = useUI()
    const { t } = useTranslation(['common'])
    const router = useRouter()

    useEffect(() => {
        const handleRouteChange = () => {
            // Remove the toast when the user navigates to a new page
            closeToast()
        }

        router.events.on('routeChangeStart', handleRouteChange)

        return () => {
            router.events.off('routeChangeStart', handleRouteChange)
        }
    }, [router, openToast])

    const renderAlertMessage = (inputArgs: RenderAlertMessageProps) => {
        const {
            errorHeading,
            successHeading,
            errorSubHeading,
            successSubHeading,
            href,
            linkText
        } = inputArgs
        const linkElement =
            href && linkText ? <Link href={href}>{t(linkText)}</Link> : <></>

        const messageWithLink = (
            <p>
                {successSubHeading} {linkElement}
            </p>
        )
        setTimeout(
            () =>
                openToast(
                    <AlertMessage
                        heading={errorHeading ?? successHeading ?? ''}
                        message={errorSubHeading ?? messageWithLink}
                    />,
                    errorHeading ? ToastType.Danger : ToastType.Success,
                    false,
                    true,
                    false,
                    SUCCESS_TOAST_STAY_TIME
                ),
            TIMEOUT_AFTER_OPENING_TOAST
        )
    }

    return {
        renderAlertMessage
    }
}
