import '@styles/main.css'
import '@styles/chrome-bug.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'swiper/swiper-bundle.css'
import { FC, useEffect } from 'react'
import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import { ManagedUIContext } from '@corratech/pylot-ui/context'
import { ManagedAuthContext } from '@corratech/pylot-auth-manager'
import { Head } from '@components/common'
import { TagContextInit } from '@corratech/pylot-tag-manager'
import { useGTM } from '@corratech/pylot-gtm'
import smoothscroll from 'smoothscroll-polyfill'
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary'
import i18nextConfig from 'next-i18next.config'
import { useSWRConfig } from 'swr'
import { useCart, useCreateCart } from '@corratech/pylot-cart-manager'
import { useStoreConfig } from 'config'
import { initDatadog } from '@lib/utils/datadog'
require('polyfill-object.fromentries')
import { Kameron, Montserrat } from 'next/font/google'
import dynamic from 'next/dynamic'
import { LiveChatScripts } from '@components/LiveChat'
import { useRouter } from 'next/router'

const Toast = dynamic(() => import('@components/common/Toast'))
const RiskifiedBeacon = dynamic(
    () => import('@components/common/RiskifiedBeacon')
)
const LivePrices = dynamic(() => import('@components/common/LivePrices'))
const LoadingIndicator = dynamic(() =>
    import('@components/LoadingIndicator').then((mod) => mod.LoadingIndicator)
)

const kameron = Kameron({
    weight: '700',
    subsets: ['latin'],
    display: 'swap'
})
const montserrat = Montserrat({
    weight: ['400', '500', '600', '700', '800'],
    subsets: ['latin'],
    display: 'swap'
})

export const USGB_GUIDE_URL = '/content/usgb-guide-ecomm'

const Noop: FC = ({ children }) => <>{children}</>

function MyApp({ Component, pageProps }: AppProps) {
    useCreateCart()
    const Layout = (Component as any).Layout || Noop
    const authRequired = (Component as any).Protected || false
    const { mutate } = useSWRConfig()
    const { asPath } = useRouter()
    const { data, mutate: cartMutate } = useCart()
    const {
        base: { datadog }
    } = useStoreConfig()
    useEffect(() => {
        if (smoothscroll) {
            smoothscroll.polyfill()
        }
        document.body.classList?.remove('loading')
        initDatadog(datadog)
    }, [])

    const handleClearSwrCache = () => {
        // docs: https://swr.vercel.app/docs/advanced/cache#modify-the-cache-data
        mutate(
            () => true, // which cache keys are updated
            undefined, // update cache data to `undefined`
            { revalidate: false } // do not revalidate
        )
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('clearSwrCache', handleClearSwrCache)
        }
        return () => {
            window.removeEventListener('clearSwrCache', handleClearSwrCache)
        }
    }, [])

    const { gtmHandler, gtmInitScript, gtmNoScript } = useGTM()

    return (
        <>
            <style jsx global>{`
                :root {
                    --font-kameron: ${kameron.style.fontFamily};
                    --font-montserrat: ${montserrat.style.fontFamily};
                }
            `}</style>
            <ErrorBoundary global>
                <Head />
                <RiskifiedBeacon data={data} cartMutate={cartMutate} />
                <LivePrices />
                <TagContextInit
                    handlers={[gtmHandler]}
                    scripts={[
                        {
                            src: gtmInitScript,
                            strategy: 'beforeInteractive'
                        }
                    ]}
                >
                    <div dangerouslySetInnerHTML={{ __html: gtmNoScript }} />
                    <ManagedUIContext LoadingIndicator={LoadingIndicator}>
                        <ManagedAuthContext>
                            <Layout
                                pageProps={pageProps}
                                authRequired={authRequired}
                            >
                                <ErrorBoundary>
                                    <Toast />
                                    <Component {...pageProps} />
                                </ErrorBoundary>
                            </Layout>
                        </ManagedAuthContext>
                    </ManagedUIContext>
                </TagContextInit>
            </ErrorBoundary>
            {asPath != USGB_GUIDE_URL && <LiveChatScripts />}
        </>
    )
}

export default appWithTranslation(MyApp, i18nextConfig)
