import { FC } from 'react'
import NextHead from 'next/head'

const preconnectDomains = [
    'https://s3.amazonaws.com',
    'https://cdn.livechatinc.com',
    'https://secure.livechatinc.com',
    'https://mgi.usgoldbureau.com/'
]

const Head: FC = () => {
    return (
        <NextHead>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
            />
            {preconnectDomains.map((dom) => (
                <link key={`site_${dom}`} rel="preconnect" href={dom} />
            ))}
        </NextHead>
    )
}

export default Head
