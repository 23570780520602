import { addToCartFields } from '@pylot-data/core-queries/cart'

export const CUSTOM_CART_QUERY = /* GraphQL */ `
    query getCart($cartId: String!, $isSignedIn: Boolean = false) {
        cart(cart_id: $cartId) {
            ${addToCartFields}
        }
    }
`
export const CUSTOM_CUSTOMER_CART_QUERY = /* GraphQL */ `
    query getCustomerCart($isSignedIn: Boolean!, $isCart: Boolean = false) {
        cart: customerCart(isCart: $isCart) {
            ${addToCartFields}
        }
    }
`
