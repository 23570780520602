import type { SelectedOptions } from '@corratech/pylot-variant-selector'
import { useOptionSelector } from '@corratech/pylot-variant-selector'
import { ProductTypeEnum } from '@pylot-data/enums/ProductTypeEnum.d'
import type {
    ConfigurableAttributeOption,
    ConfigurableProductOptionsValues,
    ConfigurableVariant,
    Maybe,
    MediaGalleryInterface,
    SimpleProduct
} from '@pylot-data/fwrdschema'
import type { ConfigurableProductOptions } from '@pylot-data/pylotschema'
import { useTranslation } from 'next-i18next'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { getButtonLabel } from './utils/getButtonLabel'
import { getMediaGalleryEntries } from './utils/getMediaGalleryEntries'
import { useRouter } from 'next/router'

// Redefining here, as importing original enum as a value from (fwrd/pylot)schema does not work
export enum ProductStockStatus {
    InStock = 'IN_STOCK',
    OutOfStock = 'OUT_OF_STOCK'
}

export enum OptionType {
    VisualSwatch = 'VisualSwatch',
    TextSwatch = 'TextSwatch',
    Dropdown = 'Dropdown',
    Other = 'Other'
}

export type Option = {
    displayName: string
    code: string
    values: Maybe<ConfigurableProductOptionsValues[]>
    type: OptionType | null
}

export enum ButtonLabel {
    OUT_OF_STOCK = 'Out of stock',
    SELECT_A_VARIANT = 'Select a variant',
    ADD_TO_CART = 'Add to cart',
    UPDATE_CART_ITEM = 'Update'
}

export enum ProductVariant {
    NOT_EXIST = -1,
    NOT_SELECTED = -2
}

export type UseProductUIReturn<ProductTypeEnum> = {
    setSelectedOptions: Dispatch<SetStateAction<SelectedOptions>>
    selectedOptions: SelectedOptions
    options: Option[]
    selectedVariantIndex: number
    variant: SimpleProduct | ProductTypeEnum
    mediaGalleryEntries: Array<Maybe<MediaGalleryInterface>>
    buttonLabel: string
    isButtonDisabled: boolean
    isConfigOptionSelected: boolean
    selectedOptionUIDs: string[]
    quantity: number
    setQuantity: Dispatch<SetStateAction<number>>
}

type FilterObject = {
    [key: string]: string
}

export type UseProductUi<ProductTypeEnum> = (
    product: ProductTypeEnum,
    options?: {
        url_key?: string
        preselectedOptions?: Record<string, string>
        buttonActionLabel?: ButtonLabel
    }
) => UseProductUIReturn<ProductTypeEnum>

export const useProductUI: UseProductUi<any> = (
    product,
    { preselectedOptions, buttonActionLabel = ButtonLabel.ADD_TO_CART } = {
        buttonActionLabel: ButtonLabel.ADD_TO_CART
    }
) => {
    const router = useRouter()

    // getting filter directly from asPath, as it is not available in query at the time of initial render
    // this is used to get the filtered variant and render its correct gallery
    const urlFilterQuery = useMemo(() => {
        const queryString = router.asPath.split('?')[1] || ''
        const urlSearchParams = new URLSearchParams(queryString)
        const filterParamValue = urlSearchParams.get('filter') || ''
        const keyValuePairs = filterParamValue.split(':')
        const urlFilterQuery: Record<string, string> = {}

        if (keyValuePairs.length === 2) {
            const [key, value] = keyValuePairs
            urlFilterQuery[key] = value
        }

        return urlFilterQuery
    }, [router.asPath])

    const { t } = useTranslation(['common'])

    const [quantity, setQuantity] = useState<number>(1)
    const productType = product?.__typename as ProductTypeEnum | undefined
    const isConfig = productType === ProductTypeEnum.CONFIGURABLE_PRODUCT

    const filteredVariantIndex = useMemo(() => {
        const index = (product?.variants as ConfigurableVariant[])?.findIndex(
            (prodVar) => {
                const { attributes: variantAttributes } = prodVar
                return (
                    (variantAttributes as ConfigurableAttributeOption[]).find(
                        (attribute) =>
                            parseInt(
                                urlFilterQuery[
                                    attribute.code as keyof FilterObject
                                ]
                            ) === attribute.value_index
                    ) !== undefined
                )
            }
        )
        return index
    }, [urlFilterQuery])

    const { options, selectedOptions, setSelectedOptions } = useOptionSelector(
        product?.configurable_options as ConfigurableProductOptions[],
        preselectedOptions,
        true
    )

    const selectedVariantIndex = useMemo(() => {
        if (!product?.variants) return ProductVariant.NOT_EXIST
        const currentlySelectedOptions = Object.values(selectedOptions).filter(
            (v) => !!v
        )

        const areAllOptionsSelected =
            currentlySelectedOptions.length ===
            Object.keys(selectedOptions).length

        if (!areAllOptionsSelected) return ProductVariant.NOT_SELECTED

        const newVariantIndex = (
            product?.variants as ConfigurableVariant[]
        )?.findIndex((prodVar) => {
            const { attributes: variantAttributes } = prodVar
            return (variantAttributes as ConfigurableAttributeOption[]).every(
                (attribute) =>
                    selectedOptions[attribute.code as keyof SelectedOptions] ===
                    attribute.label
            )
        })

        return newVariantIndex
    }, [selectedOptions])

    const variant =
        product?.variants?.[selectedVariantIndex]?.product ?? product

    const isButtonDisabled =
        (isConfig && selectedVariantIndex === ProductVariant.NOT_EXIST) ||
        (product as SimpleProduct)?.stock_status ===
            ProductStockStatus.OutOfStock ||
        variant?.stock_status === ProductStockStatus.OutOfStock ||
        (isConfig && selectedVariantIndex === ProductVariant.NOT_SELECTED)

    const isConfigOptionSelected =
        isConfig && selectedVariantIndex === ProductVariant.NOT_SELECTED

    const { attributes } = product?.variants?.[selectedVariantIndex] ?? {}

    const selectedOptionUIDs =
        (attributes as ConfigurableAttributeOption[])?.map(
            (attribute) => attribute?.uid ?? ''
        ) ?? []

    //get the media galleries to render the gallery component
    const mediaGalleryEntries = useMemo(() => {
        // change the gallery entries if all options are not selected
        const variantId = (
            product.variants as ConfigurableVariant[]
        )?.findIndex((prodVar) => {
            const { attributes: variantAttributes } = prodVar
            return (
                (variantAttributes as ConfigurableAttributeOption[]).find(
                    (attribute) =>
                        selectedOptions[
                            attribute.code as keyof SelectedOptions
                        ] === attribute.label
                ) !== undefined
            )
        })
        if (
            (selectedVariantIndex === ProductVariant.NOT_SELECTED &&
                filteredVariantIndex > -1 &&
                Object.values(selectedOptions).every(
                    (value) => value === null
                )) ||
            variantId === filteredVariantIndex
        ) {
            return getMediaGalleryEntries(
                product,
                filteredVariantIndex ?? ProductVariant.NOT_SELECTED
            )
        }

        if (
            selectedVariantIndex === ProductVariant.NOT_SELECTED &&
            Object.values(selectedOptions).some((value) => {
                return value === null
            }) &&
            filteredVariantIndex > -1
        ) {
            return getMediaGalleryEntries(product, variantId)
        }
        // if all options are selected, return the variant media gallery with the selected variant index
        return getMediaGalleryEntries(product, selectedVariantIndex)
    }, [selectedOptions])

    //get button text status
    const buttonLabel = useMemo(() => {
        const label = getButtonLabel(
            isConfig,
            product?.stock_status,
            variant?.stock_status,
            selectedVariantIndex,
            buttonActionLabel
        )
        return t(label)
        // TODO: check if that brings any benefit
    }, [
        buttonActionLabel,
        isConfig,
        variant?.stock_status,
        product?.stock_status,
        selectedVariantIndex,
        t
    ])

    return {
        setSelectedOptions,
        selectedOptions,
        setQuantity,
        quantity,
        options,
        selectedVariantIndex,
        variant,
        mediaGalleryEntries,
        buttonLabel,
        isButtonDisabled,
        isConfigOptionSelected,
        selectedOptionUIDs
    }
}
