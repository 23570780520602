import { FC, ReactElement } from 'react'

export const AlertMessage: FC<{
    heading?: string
    message?: string | JSX.Element
}> = ({ heading, message, children }): ReactElement => {
    if (typeof message === 'string') {
        const septateMessageByNewLine = message?.split('\n')
        return (
            <div className="text-left">
                {heading && (
                    <h3 className="toast-title-text hidden md:block">
                        {heading}
                    </h3>
                )}
                {septateMessageByNewLine?.map((msg) => (
                    <p key={msg} className="p-0 m-0">
                        {msg}
                    </p>
                ))}
                {children}
            </div>
        )
    } else {
        return (
            <div className="text-left">
                {heading && (
                    <h3 className="toast-title-text hidden md:block">
                        {heading}
                    </h3>
                )}
                {message}
                {children}
            </div>
        )
    }
}
