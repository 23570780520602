import type {
    SeoConfig,
    DefaultSeoConfig,
    OrganizationSeoConfig,
    OrganizationContentSeo,
    OrganizationAddressSeo,
    OrganizationContactPointSeo,
    ProductSeoConfig,
    ProductAttributesSeoConfig,
    ProductImageSeoConfig,
    TagManagerConfig,
    Maybe
} from '@preload/graphql/getConfig.d'
import type { CustomPages } from './seo/customPages'
import { customPages } from './seo/customPages'
import type { CheckoutSteps } from './seo/checkoutSteps'
import { checkoutSteps } from './seo/checkoutSteps'

export enum ItemCondition {
    NEW = 'https://schema.org/NewCondition',
    USED = 'https://schema.org/UsedCondition',
    DAMAGED = 'https://schema.org/DamagedCondition',
    REFURBISHED = 'https://schema.org/RefurbishedCondition'
}

export type FrontendSeoConfig = SeoConfig & {
    default: DefaultSeoConfig & {
        title: string
    }
    product: ProductSeoConfig & {
        attributes: ProductAttributesSeoConfig
        image: ProductImageSeoConfig & {
            count: number
            width: number
        }
    }
    organization?: OrganizationSeoConfig & {
        content: OrganizationContentSeo & {
            '@context': string
            '@type': string
            address?: OrganizationAddressSeo & {
                '@type': string
            }
            contactPoint?: Array<
                Maybe<OrganizationContactPointSeo & { '@type'?: string }>
            > | null
            legalname?: Maybe<string>
            description?: Maybe<string>
        }
    }
    customPages: CustomPages
    multiStoreUrlParamCount: number
    checkoutSteps: CheckoutSteps
    tagManager: TagManagerConfig
}

export const seo: FrontendSeoConfig = {
    default: {
        title: '',
        twitter: {
            site: '@GoCorra',
            cardType: 'summary_large_image',
            creator: '@GoCorra'
        }
    },
    product: {
        attributes: {},
        image: {
            count: 1,
            width: 620
        }
    },
    organization: {
        content: {
            '@context': 'https://schema.org',
            '@type': 'Corporation',
            address: {
                '@type': 'PostalAddress'
            }
        }
    },
    customPages,
    multiStoreUrlParamCount: 0,
    checkoutSteps,
    tagManager: {}
}

export default seo
