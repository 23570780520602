import { useRouter } from 'next/router'
import type { PylotFrontendConfig } from '../config.d'
import routerLocaleInfo from '../../next-i18next.router-locale-info.js'

export const getStoreCode = (routerLocale?: string): string => {
    if (!routerLocale) {
        return routerLocaleInfo.en.storeCode
    }
    return (
        routerLocaleInfo[routerLocale]?.storeCode ??
        routerLocaleInfo.en.storeCode
    )
}

/**
 * The getStoreCode needs to be declared here to prevent Vercel's build break
 */
declare global {
    function getStoreCode(routerLocale?: string): string
}

/**
 * declare `getStoreCode` globally to let this method be used in preload
 * inside of Magento's service.
 * this code is currently being used in `magento/src/server/executeBackendQuery.ts` file.
 */
global.getStoreCode = getStoreCode

// Load store-specific (if available) or default config
export const getStoreConfig = (locale?: string): PylotFrontendConfig => {
    // Important: do not insert this function call into the next require call (will cause errors)
    const storeCode = getStoreCode(locale)
    /* eslint-disable @typescript-eslint/no-var-requires */
    const { config } = require(`@config/stores/${storeCode}`) as {
        config: PylotFrontendConfig
    }
    return config
}

export const useStoreConfig = (): PylotFrontendConfig => {
    const { locale } = useRouter()
    return getStoreConfig(locale)
}
