const User = ({ ...props }) => {
    return (
        <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8.3125 3.5C8.3125 2.91984 8.08203 2.36344 7.6718 1.9532C7.26156 1.54297 6.70516 1.3125 6.125 1.3125C5.54484 1.3125 4.98844 1.54297 4.5782 1.9532C4.16797 2.36344 3.9375 2.91984 3.9375 3.5C3.9375 4.08016 4.16797 4.63656 4.5782 5.0468C4.98844 5.45703 5.54484 5.6875 6.125 5.6875C6.70516 5.6875 7.26156 5.45703 7.6718 5.0468C8.08203 4.63656 8.3125 4.08016 8.3125 3.5ZM2.625 3.5C2.625 2.57174 2.99375 1.6815 3.65013 1.02513C4.3065 0.368749 5.19674 0 6.125 0C7.05326 0 7.9435 0.368749 8.59987 1.02513C9.25625 1.6815 9.625 2.57174 9.625 3.5C9.625 4.42826 9.25625 5.3185 8.59987 5.97487C7.9435 6.63125 7.05326 7 6.125 7C5.19674 7 4.3065 6.63125 3.65013 5.97487C2.99375 5.3185 2.625 4.42826 2.625 3.5ZM1.34805 12.6875H10.902C10.6586 10.9566 9.17109 9.625 7.37461 9.625H4.87539C3.07891 9.625 1.59141 10.9566 1.34805 12.6875ZM0 13.1879C0 10.4945 2.18203 8.3125 4.87539 8.3125H7.37461C10.068 8.3125 12.25 10.4945 12.25 13.1879C12.25 13.6363 11.8863 14 11.4379 14H0.812109C0.363672 14 0 13.6363 0 13.1879Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default User
